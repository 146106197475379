import * as React from "react"
import Helmet from "react-helmet";

import Layout from "../components/Layout"
import Banner from "../components/Banner"
import Contact from "../components/Contact"

const Contactpage = () => {
    return (
        <>
            <Helmet htmlAttributes={{
                lang: 'pl',
                }}>
                <title>Dane kontaktowe - Kancelaria Notarialna M.Dąbrowska & J.Szeszkowska</title>
                <meta name="description" content="Kancelaria Notarialna Warszawa Centrum. Akty notarialne, wymagane dokumenty i opłaty. Informacje o czynnościach notarialnych. Notariusz śródmieście." />
            </Helmet>
            <Layout>
                <Banner />
                <Contact />
            </Layout>
        </>

    );
};
export default Contactpage;