import React, { useState } from "react";
import { Link } from "gatsby"
import { useForm } from 'react-hook-form';
import axios from 'axios';

import { ContactBLock, Container, MapBlock, Grid, Item, ContactText, Address, Icon, FormBlock, FormGrid, Travel, FormInner, BtnOuter } from "./styled"

const ContactSection = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [reqStatus, setReqStatus] = useState('');


    const onSubmit = async (data) => {
        if(reqStatus !== 'done'){
            try{
                setReqStatus('pending');

                const res = axios.post('https://notariuszeds.pl/API/send-contact-form.php', data);

                setReqStatus('done');

            } catch (err) {
                setReqStatus('error');
            }
        }
    }

    return (
        <>

            <ContactBLock>
                <MapBlock>
                    <Grid>
                        <Item>
                            <ContactText>
                                <h1>Kontakt</h1>
                                <p><span>Kancelaria Notarialna M.Dąbrowska notariusz J.Szeszkowska notariusz S.C.</span></p>
                                <address>
                                    <p>Al. Niepodległości 245 lok. 103<br />
                                    02-009 Warszawa<br /> NIP: 701 005 17 64
                                    </p>
                                </address>
                                <p>
                                    <span>Godziny otwarcia</span><br />pon. - pt. 09:00 - 17:00<br />
                                    W wypadku konieczności  dokonania czynności poza godzinami urzędowania kancelarii prosimy o kontakt z kancelarią w celu ustalenia terminu i godziny spotkania.
                                </p>

                                <Address>
                                    <Icon>
                                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M48.1836 2.40335L38.0276 0.0596485C36.9241 -0.194252 35.7913 0.381907 35.3421 1.41704L30.6547 12.3543C30.2445 13.3113 30.518 14.4343 31.3285 15.0886L37.2463 19.9323C33.7308 27.4224 27.5883 33.6527 19.942 37.2366L15.0984 31.3188C14.4343 30.5082 13.3211 30.2348 12.364 30.6449L1.42677 35.3323C0.381869 35.7913 -0.194291 36.9241 0.0596097 38.0276L2.40331 48.1836C2.64745 49.2383 3.58493 50 4.68842 50C29.6977 50 50 29.7368 50 4.68846C50 3.59473 49.2481 2.64748 48.1836 2.40335Z" fill="#DED1BC" />
                                        </svg>
                                    </Icon>
                                    <p>
                                        <a href="tel:224054426">(22) 405 44 26</a>
                                        <a href="tel:224054427">(22) 405 44 27</a>
                                        <a href="tel:224054428">(22) 405 44 28</a>
                                    </p>
                                </Address>
                                <Address>
                                    <Icon>
                                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24 0C10.7362 0 0 10.7341 0 24C0 37.2638 10.7341 48 24 48C28.6601 48 33.2266 46.6316 37.104 44.1075C38.2658 43.3512 38.5193 41.757 37.6413 40.6844L36.6564 39.4812C35.9141 38.5743 34.6068 38.3521 33.6203 38.9848C30.7665 40.8152 27.4175 41.8064 24 41.8064C14.1815 41.8064 6.19355 33.8185 6.19355 24C6.19355 14.1815 14.1815 6.19355 24 6.19355C33.6909 6.19355 41.8064 11.7696 41.8064 21.6774C41.8064 25.4309 39.7652 29.3944 36.1771 29.7767C34.4982 29.7328 34.5406 28.5325 34.873 26.8712L37.1407 15.1509C37.418 13.7177 36.3201 12.3871 34.8605 12.3871H30.5075C30.1861 12.3871 29.8759 12.5054 29.6362 12.7195C29.3964 12.9335 29.2439 13.2284 29.2076 13.5477L29.2066 13.5566C27.7844 11.8243 25.2923 11.4494 23.403 11.4494C16.1856 11.4494 10.0645 17.472 10.0645 26.1068C10.0645 32.4264 13.6244 36.3523 19.3548 36.3523C21.9662 36.3523 24.9067 34.839 26.612 32.6426C27.5335 35.943 30.5423 35.9429 33.4549 35.9429C43.9944 35.9429 48 29.0127 48 21.6774C48 8.48255 37.3571 0 24 0ZM21.9019 29.461C19.7488 29.461 18.4113 27.9491 18.4113 25.5154C18.4113 21.1612 21.3899 18.4771 24.0852 18.4771C26.2425 18.4771 27.5304 19.952 27.5304 22.4226C27.5304 26.7833 24.2522 29.461 21.9019 29.461Z" fill="#DED1BC" />
                                        </svg>
                                    </Icon>
                                    <p>
                                        <a href="mailto:m.dabrowska@notariusze.waw.pl">m.dabrowska@notariusze.waw.pl</a>
                                        <a href="tel:504295762">504 295 762</a>
                                        <a href="mailto:szeszkowska@notariusze.waw.pl">szeszkowska@notariusze.waw.pl</a>
                                        <a href="tel:516031991">516 031 991</a>
                                    </p>
                                </Address>
                            </ContactText>
                        </Item>
                        <Item><iframe title="map" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJAW60nuzMHkcR5aK0fs1Br1o&key=AIzaSyDdmaNiMg8DYOsUrH8kSl-lVfrMbaqA4vw" width="100%" height="100%" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe></Item>
                    </Grid>
                </MapBlock>
                <FormBlock>
                    <Container>
                        <FormGrid>
                            <Item>
                                <h2>Jak do nas dojechać?</h2>
                                <p>
                                    Kancelaria Notarialna mieści się na II piętrze w budynku w Warszawie, przy alei Niepodległości numer 245 lokal 103 (przy skrzyżowaniu z ulicą Koszykową, w pobliżu Dworca Centralnego).
                                    <br />Po wejściu na teren nieruchomości, prosimy kierować się w lewą stronę do końca budynku.
                                </p>
                                <Travel>
                                    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M43.8438 11.5H43.125V7.1875C43.125 3.1625 34.2125 0 23 0C11.7875 0 2.875 3.1625 2.875 7.1875V11.5H2.15625C0.96582 11.5 0 12.4649 0 13.6562V20.8438C0 22.0342 0.96582 23 2.15625 23H2.875V37.375C2.875 38.9625 4.16246 40.25 5.75 40.25V43.125C5.75 44.7125 7.03746 46 8.625 46H11.5C13.0875 46 14.375 44.7125 14.375 43.125V40.25H31.625V43.125C31.625 44.7125 32.9125 46 34.5 46H37.375C38.9625 46 40.25 44.7125 40.25 43.125V40.25H40.825C42.2625 40.25 43.125 39.1 43.125 37.95V23H43.8438C45.0342 23 46 22.0342 46 20.8438V13.6562C46 12.4649 45.0342 11.5 43.8438 11.5ZM14.375 6.46875C14.375 6.07164 14.6966 5.75 15.0938 5.75H30.9062C31.3034 5.75 31.625 6.07164 31.625 6.46875V7.90625C31.625 8.30336 31.3034 8.625 30.9062 8.625H15.0938C14.6966 8.625 14.375 8.30336 14.375 7.90625V6.46875ZM10.0625 35.9375C8.47496 35.9375 7.1875 34.65 7.1875 33.0625C7.1875 31.475 8.47496 30.1875 10.0625 30.1875C11.65 30.1875 12.9375 31.475 12.9375 33.0625C12.9375 34.65 11.65 35.9375 10.0625 35.9375ZM21.5625 25.875H11.5C9.91246 25.875 8.625 24.5875 8.625 23V14.375C8.625 12.7875 9.91246 11.5 11.5 11.5H21.5625V25.875ZM24.4375 25.875V11.5H34.5C36.0875 11.5 37.375 12.7875 37.375 14.375V23C37.375 24.5875 36.0875 25.875 34.5 25.875H24.4375ZM35.9375 35.9375C34.35 35.9375 33.0625 34.65 33.0625 33.0625C33.0625 31.475 34.35 30.1875 35.9375 30.1875C37.525 30.1875 38.8125 31.475 38.8125 33.0625C38.8125 34.65 37.525 35.9375 35.9375 35.9375Z" fill="#113D69" />
                                    </svg>
                                    <p>Autobusy: 159, 174</p>
                                </Travel>
                                <Travel>
                                    <svg width="46" height="39" viewBox="0 0 46 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M37.3179 38.5076C39.0807 38.5076 40.5097 37.0786 40.5097 35.3158C40.5097 33.553 39.0807 32.124 37.3179 32.124C35.5552 32.124 34.1262 33.553 34.1262 35.3158C34.1262 37.0786 35.5552 38.5076 37.3179 38.5076Z" fill="#113D69" />
                                        <path d="M45.816 1.13394C45.5866 0.276149 44.8685 -0.0530028 43.8611 0.006843L1.9491 0.0766627C1.10129 0.306071 0.5926 1.17384 0.822009 2.03162C1.01152 2.7398 1.64988 3.20859 2.35805 3.20859C2.49769 3.20859 2.63733 3.18864 2.767 3.15872L24.7005 2.99913L21.3591 7.20828H3.80432C2.03887 7.20828 0.612549 8.6346 0.612549 10.4001V32.7425C0.612549 34.5079 2.03887 35.9342 3.80432 35.9342H42.1056C43.8711 35.9342 45.2974 34.5079 45.2974 32.7425V10.4001C45.2974 8.6346 43.8711 7.20828 42.1056 7.20828H24.5508L27.8922 3.049L44.679 3.09887C45.5368 2.85949 46.0355 1.98175 45.816 1.13394ZM14.9755 23.1672H5.40021V13.5918H14.9755V23.1672ZM30.9344 13.5918H40.5097V23.1672H30.9344V13.5918ZM27.7426 13.5918V23.1672H18.1673V13.5918H27.7426Z" fill="#113D69" />
                                        <path d="M9.10071 38.5076C10.8635 38.5076 12.2925 37.0786 12.2925 35.3158C12.2925 33.553 10.8635 32.124 9.10071 32.124C7.33794 32.124 5.90894 33.553 5.90894 35.3158C5.90894 37.0786 7.33794 38.5076 9.10071 38.5076Z" fill="#113D69" />
                                    </svg>
                                    <p>Tramwaje: 10, 17, 33</p>
                                </Travel>
                            </Item>
                            <Item>
                                <FormInner>
                                    <h2>Formularz Kontaktowy</h2>

                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <input {...register('name', { required: true })} placeholder="Imię i nazwisko" />
                                        {errors.name && <p className="error">Wpisz Imię i nazwisko</p>}
                                        <input
                                            placeholder="Adres email"
                                            {...register('email', {
                                                required: 'Email is required',
                                                pattern: {
                                                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                                    message: 'Adres email nie wygląda na poprawny',
                                                },
                                            })}
                                            type="email"
                                        />
                                        {errors.email && <p className="error">Wpisz adres email</p>}

                                        <input {...register('temat', { required: true })} placeholder="Temat" />
                                        {errors.temat && <p className="error">Wpisz temat wiadomości</p>}
                                        <textarea {...register('message', { required: true })} placeholder="Treść wiadomości"></textarea>
                                        {errors.message && <p className="error">Wpisz wiadomość</p>}

                                        {reqStatus === 'pending' &&
                                            <div>Wysyłanie...</div>
                                        }
                                        {reqStatus === 'done' &&
                                            <div>Wiadomość wysłana!</div>
                                        }
                                        {reqStatus === 'error' &&
                                            <div>Wystąpił błąd. Prosimy o kontakt na podane adresy email.</div>
                                        }

                                        <BtnOuter>
                                            <input type="submit" value="WYŚLIJ" className="btn" />
                                        </BtnOuter>
                                    </form>
                                </FormInner>
                            </Item>
                        </FormGrid>
                    </Container>
                </FormBlock>
            </ContactBLock>
        </>
    );

};
export default ContactSection;